import axios from "../utils/CustomAxios";

const ProductAPI = {
  create: (data) => {
    return axios.post("v1/panel/products", data);
  },
  get: (id) => {
    return axios.get("v1/panel/products/" + id);
  },
  getAll: (data = {}) => {
    return axios.get("v1/panel/products", {
      params: {
        page: 0,
        limit: 100,
        order_index: "products.id",
        order_direction: "desc",
        ...data,
      },
    });
  },
  delete: (id) => {
    return axios.delete("v1/panel/products/" + id);
  },
  deleteAll: () => {
    return axios.delete("v1/panel/products/all");
  },
  deleteProductsAfter: (productId) => {
    return axios.delete("v1/panel/products/last", {
      data: { product_id: productId },
    });
  },
  update: (id, data) => {
    return axios.put("v1/panel/products/" + id, data);
  },
  copy: (id) => {
    return axios.post("v1/panel/products/copy/" + id);
  },
};

export default ProductAPI;
